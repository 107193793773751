import React from 'react';
import { BlogNewsroom2023 } from 'views/Archive2023';

const BlogNewsroomPage = () => {

return <BlogNewsroom2023/>
};

export default BlogNewsroomPage;

// import React from 'react';

// import { PickAndSpeak2024 } from '../views';

// const BlogNewsroomPage = () => {

// return <PickAndSpeak2024/>
// };

// export default BlogNewsroomPage;